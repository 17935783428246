import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Liste des clients",

            vuetable: {
                moreParams: {},
                fields: [

                    {
                        name: 'nom_client',
                        title: 'Nom',
                        sortField: 'nom_client',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'phone_client',
                        title: 'Contact',
                        sortField: 'phone_client',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'email_client',
                        title: 'Email',
                        sortField: 'email_client',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'adresse_client',
                        title: 'Adresse',
                        sortField: 'adresse_client',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'nif',
                        title: 'NIF',
                        sortField: 'nif',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'stat',
                        title: 'STAT',
                        sortField: 'stat',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'rcs',
                        title: 'RCS',
                        sortField: 'rcs',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'total_debit',
                        title: 'DEBIT',
                        sortField: 'total_debit',
                        dataClass: 'text-right text-bold',
                    },
                    {
                        name: 'total_credit',
                        title: 'CREDIT',
                        sortField: 'total_credit',
                        dataClass: 'text-right text-bold',
                    },
                    {
                        name: 'total_solde',
                        title: 'SOLDE',
                        sortField: 'total_solde',
                        dataClass: 'text-right text-bold',
                    },
                    {
                        name: 'actions',
                        width: "80px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    { field: 'nom_client', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },

            motCle: "",
            crudform: {
                id: "",
                nom_client: "",
                email_client: "",
                phone_client: "",
                adresse_client: "",
                nif: "",
                stat: "",
                rcs: "",
            },
            crudmodaltitle: "Ajouter un client",


        }
    },
    methods: {
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            return  (dataItem.nom_client === 'TOTAL') ? 'bg-dark' :'';
        },

        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {

            axios.get(this.BASE_URL + "/clients/get/" + rowData.id).then(response => {
                this.crudform = response.data[0];
            });
            this.crudmodaltitle = "Editer un client";
            this.openModal();
        },

        voirListe(rowData){
            this.$router.push('/client/dossier/liste/'+rowData.id);
        },

        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/clients/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Ajouter un utilisateur";
            this.crudform = {
                id: "",
                nom_client: "",
                email_client: "",
                phone_client: "",
                adresse_client: "",
                nif: "",
                stat: "",
                rcs: "",
            };
            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/clients/addaction" : that.BASE_URL + "/clients/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },


    },
    watch: {

    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
},
    mounted: function() {

        console.log(process);
        console.log(process.env);
        console.log(process.env.STRIPE_TOKEN);

    }
}
